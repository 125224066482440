import db from "./config/firebase";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { email, password } from "../config/auth";


class FileUploads {

	uploadOfferImage = async (image, fileRef) => {

        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, email, password)
                .then(() => {

                    const storage = getStorage();
                    const extension = image.name.split(".").pop();
                    const storageRef = ref(storage, fileRef)

                    uploadBytes(storageRef, image)
                        .then(() => {
                            resolve('File uploaded successfully')
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            reject(`Error with uploading file: Error code: ${errorCode} - ${errorMessage}`)
                        })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    reject(`Error with connecting to firebase: Error code: ${errorCode} - ${errorMessage}`)
                });
        })

    }
}

export default FileUploads
