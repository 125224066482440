/**
 * Calculates the date of the next occurrence of a given day of the week.
 * @param {string} day - The name of the day of the week.
 * @returns {Date} - The date of the next occurrence of the given day.
 */
const nextDay = (day) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const targetDayNumber = days.indexOf(day);
    const today = new Date();

    let nextDay = new Date(today);
    nextDay.setDate(today.getDate() + (((targetDayNumber + 7 - today.getDay()) % 7) || 7))
    return nextDay;
}

export {
    nextDay
}
