// src/components/PartnerList/PartnerList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './PartnerList.module.css';
import Partners from '../../../controllers/Partners'
import Header from '../../Header/Header';

const PartnerList = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        (async() => {
            // Load partners list

            const PartersInstance = await new Partners()
            let partnersList = await PartersInstance.getAll()

            
            setPartners(partnersList)

        })()
    }, []);

    useEffect(() => {
        (async() => {
            if(partners.length === 0) return

            const partnersWithoutLogo = partners.filter((partner) => (partner.logoURL === undefined))

            if(partnersWithoutLogo.length === 0) return
            const PartersInstance = await new Partners()

            console.log({partnersWithoutLogo})

            const newPartnersObject = await Promise.all(partners.map(async (partner) => {
                if(partner.logoURL !== undefined){
                    partner.logoURL = ''
                    return partner
                }
                const logo = await PartersInstance.getPartnerLogo(partner.id)
                partner.logoURL = logo
                return partner
            }))
            console.log({newPartnersObject})
            setPartners(newPartnersObject)
        })()
    }, [partners]);

    console.log({partners})

    return (
        <>
            <Header />
            <div className={styles.partnerList}>
                <h2>Liste des partenaires</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Nom du partenaire</th>
                            <th>Catégorie</th>
                            <th>Site Web</th>
                            <th>Logo</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partners.map((partner) => (
                            <tr key={partner.id}>
                                <td>{partner.partnerName}</td>
                                <td>{partner.category}</td>
                                <td>
                                    <a href={partner.website} target="_blank" rel="noopener noreferrer">
                                        {(partner.website === undefined || partner.website.lenght < 20) ? partner.website : partner.website.slice(0, 20) + '...'}
                                    </a>
                                </td>
                                <td>
                                    <img src={partner.logoURL} alt={partner.partnerName} style={{maxWidth: '100%'}}/>
                                </td>
                                <td>{partner.description}</td>
                                <td>
                                    <ul>
                                        <li><Link to={`/edit-partner/${partner.id}`} className={styles.editLink}>Modifier</Link></li>
                                        <li><Link to={`/offers/partner/${partner.id}`} className={styles.editLink}>Voir les offres</Link></li>
                                    </ul>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Link to="/add-partner" className={styles.addPartnerLink}>Ajouter un partenaire</Link>
            </div>
        </>
    );
};

export default PartnerList;
