import React from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.css";

const Modal = ({ type, message, onClose }) => {
  return (
    <div className={styles.modal}>
      <div className={`${styles.modalContent} ${styles[type]}`}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

Modal.propTypes = {
  type: PropTypes.oneOf(["success", "error"]).isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
