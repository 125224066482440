import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import db from './config/firebase';

class ActivitiesRaidEDHEC {
    /**
     * Retrieves all customers with their corresponding paywall codes.
     * @returns {Promise<Array>} An array of customers with their paywall codes.
     */
    getAllActivitiesOfTheDay = async () => {
        // const yesturday = new Date().getTime() - 86400000;
        const today00h00 = new Date().setHours(0, 0, 0, 0);
        const activitiesRef = query(collection(db, 'activities'), where('createdDate', '>=', new Date(today00h00)));

        const activitiesDocs = await getDocs(activitiesRef);
        const activities = activitiesDocs.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        console.log({ activities })

        

        return activities;
    }

    getLoutreFinishers = async () => {
        let activities = await this.getAllActivitiesOfTheDay();
        
        
        activities = activities.map(async (activity) => {

            let { user: wallet } = activity;
            const userRef = query(collection(db, 'users'), where('wallet', '==', wallet));
            const documents = await getDocs(userRef);

            let user;
            documents.forEach(async (document) => {
                user = document.data();
                
				user.id = document.id
            });


            const { nickname, email, paywallCode, gender } = user;
            return {
                ...activity,
                nickname,
                paywallCode,
                email,
                gender
            }
        });
        activities = await Promise.all(activities);

        let finishers = activities.filter(activity => activity.totalDistanceInKm
            && activity.totalDistanceInKm >= 5
            && activity.totalDistanceInKm <= 7
            && activity.paywallCode === '19011'
        );

        console.log({ finishers })
        return finishers;
    }

    getNumberOfNewUsers = async () => {
        const usersRef = query(collection(db, 'users') , where('paywallCode', '==', '19011'));
        const usersDocs = await getDocs(usersRef);
        const users = usersDocs.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        return users.length;

    }

}

export default ActivitiesRaidEDHEC;
