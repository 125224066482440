import { collection, addDoc, getDoc, getDocs, doc, updateDoc, where, query } from "firebase/firestore";
import db from './config/firebase'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { email, password } from "../config/auth";

class Partners {

	constructor(){
		return new Promise((resolve, reject) => {
			try{
				this.loginAsAdmin()
				resolve(this)
			}
			catch(e){
				reject(e)
			}
		})
	}

    getAll = async () => {

		const collectionRef = collection(db, 'partners');

        return new Promise(async(resolve, reject) => {

            const documents = await getDocs(collectionRef);
			const partners = []
            documents.forEach(async (document) => {
                const partner = document.data();
				partner.id = document.id
				partners.push(partner)
            });

			resolve(partners)
        })
    }

	getPartner = async (id) => {
		const docRef = doc(db, 'partners', id)
		const docSnap = await getDoc(docRef);

		return new Promise(async(resolve, reject) => {
			if(!docSnap.exists()){
				reject("No such document!")
			}

			const partner = docSnap.data()
			partner.id = id
			const logo = await this.getPartnerLogo(id)
			partner.logoURL = logo

			resolve(partner)
		})
	}

	updatePartner = async (id, newPartnerInfo) => {
		delete newPartnerInfo.logoURL
		const docRef = doc(db, 'partners', id)

		return new Promise(async(resolve, reject) => {

			try{
				await updateDoc(docRef, newPartnerInfo)
				resolve()
			}catch(e){
				reject(e)
			}

		})
	}

	addNewPartner = async (newPartnerInfo) => {

		return new Promise(async(resolve, reject) => {

			// Chewck if the partner already exists

			const collectionRef = collection(db, 'partners');

			const q = query(collectionRef, where('partnerName', '==', newPartnerInfo.partnerName));

			const querySnapshot = await getDocs(q);

			if(querySnapshot.size > 0){
				return reject("Partner already exists")
			}

			// If not, add it to the database

			try{
				await addDoc(collectionRef, newPartnerInfo)
				// get the new partner id
				const newPartner = await getDocs(q)
				const newPartnerId = newPartner.docs[0].id
				resolve(newPartnerId)
			}catch(e){
				reject(e)
			}
		})

	}

	loginAsAdmin = async () => {
		const auth = getAuth();
		return new Promise(async(resolve, reject) => {
			try{
				await signInWithEmailAndPassword(auth, email, password)
				resolve()
			}catch(e){
				reject(e)
			}
		})
	}

	getPartnerLogo = async (partnerId) => {
		const docRef = doc(db, 'partners', partnerId)
		const docSnap = await getDoc(docRef);

		return new Promise(async(resolve, reject) => {
			if(!docSnap.exists()){
				reject("No such document!")
			}

			const partner = docSnap.data()
			const logoUri = partner.logo

			if(logoUri === undefined || logoUri === ''){
				return resolve(null)
			}

			const storage = getStorage();
			const storageRef = ref(storage, logoUri);
			const logo = await getDownloadURL(storageRef)

			resolve(logo)
		})
	}
}

export default Partners
