import { useState } from "react";
import Header from "../../Header/Header";
import styles from './AddCustomer.module.css';
import Customers from "../../../controllers/Customers";
import { useNavigate } from 'react-router-dom';

const AddCustomer = () => {

    const [customer, setCustomer] = useState({
        name: '',
    });
    const [error, setError] = useState(null)
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer({
            ...customer,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        (async () => {
        
            try{
                const CustomerInstance = await new Customers()
                const id = await CustomerInstance.addNewCustomer(customer)

                navigate(`/customers`);
            }catch(e){
                console.error(e)
                setError(e)
            }
        })()

    };

    return (
        <>
            <Header />
            <div className={styles.addCustomer}>
                <h2>Ajouter une entreprise cliente</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Nom de l'entreprise"
                        value={customer.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="code"
                        placeholder="Code d'inscription de l'entreprise"
                        value={customer.code}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Ajouter l'entreprise</button>
                </form>
            </div>
        </>
    );
}

export default AddCustomer;
