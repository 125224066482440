import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AddPartner from './components/Partners/AddPartner/AddPartner';
import PartnerList from './components/Partners/PartnerList/PartnerList';
import EditPartner from './components/Partners/EditPartner/EditPartner';
import AddOffer from './components/Offers/AddOffer';
import OfferList from './components/Offers/OfferList';
import ExportPromoCodes from './components/Offers/ExportPromoCodes';
import Authentification from './components/Authentification/Authentification';
import { CookiesProvider, useCookies } from 'react-cookie';
import { token as RequestedToken } from './config/auth';
import CustomerList from './components/Customers/CustomerList/CustomerList';
import AddCustomer from './components/Customers/AddCustomer/AddCustomer';
import Notifications from './components/Notifications';
import ActivitiesRaidEDHEC from './controllers/ActivitiesRaidEDHEC';
import ActivitiesRaidEDHECList from './components/Activities/ActivitiesList/ActivitiesList';
import Metrics from './components/Metrics/Metrics';

function App() {

  const [cookies] = useCookies();
  const token = cookies.tokenLogin;

  if(token === undefined || token !== RequestedToken){
    return <Authentification />
  }

  return (
    <CookiesProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<PartnerList />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path='/customers' element={<CustomerList />} />
            <Route path='/add-customer' element={<AddCustomer />} />
            <Route path="/add-partner" element={<AddPartner />} />
            <Route path="/edit-partner/:id" element={<EditPartner />} />
            <Route path="/offers/add" element={<AddOffer />} />
            <Route path="/offers/add/partner/:id" element={<AddOffer />} />
            <Route path='/offers' element={<OfferList />} />
            <Route path='/offers/partner/:id' element={<OfferList />} />
            <Route path='/offers/export/:partnerName/:offerName' element={<ExportPromoCodes />} />
            <Route path='/activitiesRaidEDHEC' element={<ActivitiesRaidEDHECList />} />
            <Route path='/metrics' element={<Metrics />} />
          </Routes>
        </div>
      </Router>
    </CookiesProvider>
  );
}

export default App;
