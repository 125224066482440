import { useNavigate, useParams } from "react-router-dom";


const Header = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return(
        <div className="header">
            <table>
                <thead>
                    <tr>
                        <th><a href="/">Partenaires</a></th>
                        {id && <th><a href={`/offers/partner/${id}`}>Offres</a></th>}
                        <th><a href="/metrics">Métriques</a></th>
                        <th><a href="/customers">Clients</a></th>
                        <th><a onClick={handleGoBack} style={{textDecoration: 'underline'}}>Retour</a></th>
                    </tr>
                </thead>
            </table>
        </div>
    )

}

export default Header;
