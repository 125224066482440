import { useEffect, useState } from 'react';
import styles from './AddOffer.module.css';
import { useParams } from 'react-router-dom';
import PromoCodes from '../../controllers/PromoCodes';
import Header from '../Header/Header';

const ExportPromoCodes = () => {

    const { partnerName, offerName } = useParams();
    const [state, setState] = useState({
        promoCodes: [],
        availableFilter: false,
        filteredPromoCodes: []
    })
    const { promoCodes, availableFilter, filteredPromoCodes } = state
    

    useEffect(() => {
        (async() => {
            const PromoCodesInstance = new PromoCodes()
            const promoCodes = await PromoCodesInstance.getPromoCodes(partnerName, offerName)
            setState(s => ({ ...s, promoCodes }))
        })()

    },[partnerName, offerName])

    useEffect(() => {
        console.log({ promoCodes })
    }, [promoCodes])

    const toggleAvailableFilter = () => {
        setState(s => ({ ...s, availableFilter: !s.availableFilter }))
    }

    useEffect(() => {
        if(availableFilter){
            setState(s => ({ ...s, filteredPromoCodes: promoCodes.filter((promoCode) => promoCode.available) }))
        }else{
            setState(s => ({ ...s, filteredPromoCodes: promoCodes }))
        }
    }, [availableFilter, promoCodes])

    return (
        <>
            <Header />
            <h1>{`${partnerName} - ${offerName}`}</h1>
            <button onClick={toggleAvailableFilter}>{availableFilter ?  'Afficher les codes disponibles' : 'Afficher tous les codes'}</button> {filteredPromoCodes.length} codes {availableFilter ? 'disponibles' : 'au total'}
            <ul>
                {filteredPromoCodes.map((promoCode, index) => {
                    return <li key={index}>{promoCode.code}</li>
                })}
            </ul>
        </>
    )
}

export default ExportPromoCodes;
