import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import fetch from 'node-fetch';
import { API_KEY, APP_ID } from "../../config/OneSignalConfig";
import { nextDay } from "./DateTools";
import './style.css';

const Notifications = () => {

    const [state, setState] = useState({
        notifications: [],
        notificationsDateUpdated: null,
        error: null,
        warning: null
    });

    const parcours = [
        {
            name: "Parcours de l'Ours",
            key: 'Ours',
        },
        {
            name: "Parcours de la Loutre",
            key: 'Loutre',
        },
        {
            name: "Parcours du Dragon",
            key: 'Dragon',
        }
    ]

    const notifications = [
        {
            id: 'NJ0',
            segment: 'NJ0 segment',
            message : "Bienvenue sur OmniShape, et si on commençait par une petite marche ? 😍",
            collapse_id: 'NJ'
        },
        {
            id: 'NJ1',
            segment: 'NJ1 segment',
            message: "Et si on faisait un peu d'activité ensemble ? ✨",
            collapse_id: 'NJ'
        },
        {
            id: 'NJ2',
            segment: 'NJ2 segment',
            message: "Hey, ça fait plus de 2 jours que tu n'as pas enregistré d'activité, tout va bien ? 👀",
            collapse_id: 'NJ'
        },
        {
            id: 'NJ3',
            segment: 'NJ3 segment',
            message: "N'oublie pas de faire un peu d'activité ou d'étirements pour rester dynamique et en forme !🤗",
            collapse_id: 'NJ'
        },
        {
            id: 'Rappel0',
            segment: 'Subscribed Users',
            message: "Bon week-end, et n'oublie pas de t'étirer si tu ne fais pas d'activité ! 🏃‍♀️",
            collapse_id: 'Rappel'
        },
        {
            id: 'OSS0',
            filters: [
                {
                    key: "goal_distance",
                    field: "tag",
                    relation: "<", value: "0.5"
                },
                {   operator: "OR"  },
                {
                    key: "goal_sessions",
                    field: "tag",
                    relation: "<", value: "0.5"
                },
            ],
            message: "Hey, tu as atteint moins de 50% de tes objectifs sur la semaine, ne te décourage pas, OmniShape croit en toi ! 🫶",
            collapse_id: 'OSS'
        },
        {
            id: 'OSS1',
            filters: [
                {
                    key: "goal_distance",
                    field: "tag",
                    relation: ">", value: "0.5"
                },
                {
                    key: "goal_sessions",
                    field: "tag",
                    relation: ">", value: "0.5"
                },
            ],
            message: "Hey tu as déjà atteint plus de 50% de tes objectifs sur la semaine, reviens sur l'app pour les compléter ! 🚀",
            collapse_id: 'OSS'
        },
        {
            id: 'RaidEDHEC0Ours',
            filters: [
                {
                    key: "parcoursRaidEDHEC",
                    field: "tag",
                    relation: "=", value: "Ours"
                },
            ],
            message: "Hey, la course Parcours de l'Ours 🐻 commence dans 5 minutes. Rejoins la ligne de départ dès maintenant 🏃‍♀️",
            collapse_id: 'RaidEDHEC'
        },
        {
            id: 'RaidEDHEC0Loutre',
            filters: [
                {
                    key: "parcoursRaidEDHEC",
                    field: "tag",
                    relation: "=", value: "Loutre"
                },
            ],
            message: "Hey, la course Parcours de la Loutre 🦦 commence dans 5 minutes. Rejoins la ligne de départ dès maintenant 🏃‍♀️",
            collapse_id: 'RaidEDHEC'
        },
        {
            id: 'RaidEDHEC0Dragon',
            filters: [
                {
                    key: "parcoursRaidEDHEC",
                    field: "tag",
                    relation: "=", value: "Dragon"
                },
            ],
            message: "Hey, la course Parcours du dragon 🐉 commence dans 5 minutes. Rejoins la ligne de départ dès maintenant 🏃‍♀️",
            collapse_id: 'RaidEDHEC'
        },
        {
            id: 'RaidEDHEC1Ours',
            filters: [
                {
                    key: "parcoursRaidEDHEC",
                    field: "tag",
                    relation: "=", value: "Ours"
                },
            ],
            message: "Ta course commence dans moins de 2 minutes. Lance ta course dans l'application pour tenter de gagner nos fabuleux prix 🏆🛍️",
            collapse_id: 'RaidEDHEC'
        },
        {
            id: 'RaidEDHEC1Loutre',
            filters: [
                {
                    key: "parcoursRaidEDHEC",
                    field: "tag",
                    relation: "=", value: "Loutre"
                },
            ],
            message: "Ta course commence dans moins de 2 minutes. Lance ta course dans l'application pour tenter de gagner nos fabuleux prix 🏆🛍️",
            collapse_id: 'RaidEDHEC'
        },
        {
            id: 'RaidEDHEC1Dragon',
            filters: [
                {
                    key: "parcoursRaidEDHEC",
                    field: "tag",
                    relation: "=", value: "Dragon"
                },
            ],
            message: "Ta course commence dans moins de 2 minutes. Lance ta course dans l'application pour tenter de gagner nos fabuleux prix 🏆🛍️",
            collapse_id: 'RaidEDHEC'
        }

    ]

    const sendNotification = async (notification, scheduleDate) => {
        if(!scheduleDate){
            console.warn('No schedule date provided, sending notification in 1 minutes');
            const now = new Date();
            let In10Minutes = now;
            In10Minutes.setMinutes(now.getMinutes() + 1);
            scheduleDate = In10Minutes;
        }

        console.log(`Sending notification ${notification.id} at ${scheduleDate.toString()}`);
        const isNotificationAlreadyScheduled = state.notifications.find(notif => {
            return ( notif.name === `Notification ${notification.id}` ) && ( notif.send_after_date_format.toDateString() === new Date(scheduleDate).toDateString() )
        });

        // if(isNotificationAlreadyScheduled){
        //     setState({...state, warning: `Notification ${notification.id} already scheduled for ${scheduleDate.toString()}`});
        //     return;
        // }

        try {
            let body = {
                contents: {en: notification.message},
                name: `Notification ${notification.id}`,
                app_id: APP_ID,
                send_after: scheduleDate.toString(),
                collapse_id: notification.collapse_id
            };

            if(notification.segment) body['included_segments'] = [notification.segment];
            if(notification.include_subscription_ids) body['include_subscription_ids'] = notification.include_subscription_ids;
            if(notification.filters) body['filters'] = notification.filters;

            body = JSON.stringify(body);
            const options = {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    Authorization: `Basic ${API_KEY}`,
                    'Content-Type': 'application/json'
                },
                body: body
            };

            const response = await fetch('https://onesignal.com/api/v1/notifications', options);
            const data = await response.json();
            console.log(data);

            getNotifications();
        }
        catch (err) {
            console.error(err);
        }
    }

    const getNotifications = async () => {
        const options = {
            method: 'GET',
            headers: {accept: 'application/json', Authorization: `Basic ${API_KEY}`}
          };

        try {
            const response = await fetch(`https://onesignal.com/api/v1/notifications?app_id=${APP_ID}`, options);
            const data = await response.json();
            const activeNotifications = data.notifications.filter(notification => !notification.canceled);
            setState({notifications: activeNotifications, notificationsDateUpdated: false});
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Sets up the next Rappel0 notification to be sent on Saturday at 11:00 AM.
     */
    const setupNextRappel0 = () => {
        let scheduleDate = nextDay('Saturday');
        scheduleDate.setHours(11, 0, 0, 0);

        const Rappel0notification = notifications.find(notification => notification.id === 'Rappel0');

        sendNotification(Rappel0notification, scheduleDate);

    }

    /**
     * Sets up the next 10 daily notifications starting from a given date.
     *
     * @param {string} notificationId - The id of the notification to set up.
     * @param {number} hours - The hour of the day to send the notification.
     * @param {number} minutes - The minute of the hour to send the notification.
     * @returns {void}
     */
    const setupNext10DailyNotifications = (notificationId, hours, minutes) => {
        const notification = notifications.find(notification => notification.id === notificationId);
        let scheduleDate = new Date();
        scheduleDate.setDate(scheduleDate.getDate() + 1);
        scheduleDate.setHours(hours, minutes, 0, 0);

        for(let i = 0; i < 10; i++){
            console.log(scheduleDate);
            sendNotification(notification, scheduleDate);
            scheduleDate.setDate(scheduleDate.getDate() + 1);
            scheduleDate.setHours(hours, minutes, 0, 0);
        }
    }

    const setupNext10NJ0 = () => {
        setupNext10DailyNotifications('NJ0', 8, 0);
    }

    const setupNext10NJ1 = () => {
        setupNext10DailyNotifications('NJ1', 8, 0);
    }

    const setupNext10NJ2 = () => {
        setupNext10DailyNotifications('NJ2', 9, 0);
    }

    const setupNext10NJ3 = () => {
        setupNext10DailyNotifications('NJ3', 9, 0);
    }

    const setupNextOSS0 = async () => {
        let scheduleDate = nextDay('Thursday');
        scheduleDate.setHours(11, 0, 0, 0);

        const OSS0notification = notifications.find(notification => notification.id === 'OSS0');

        await sendNotification(OSS0notification, scheduleDate);
    }

    const setupNextOSS1 = async () => {
        let scheduleDate = nextDay('Thursday');
        scheduleDate.setHours(11, 0, 0, 0);

        const OSS1notification = notifications.find(notification => notification.id === 'OSS1');

        await sendNotification(OSS1notification, scheduleDate);
    }

    const setupNextOSS = async () => {
        await setupNextOSS0();
        await setupNextOSS1();
    }

    const setupNext10daysNJNotifications = async () => {
        await setupNext10NJ0();
        await setupNext10NJ1();
        await setupNext10NJ2();
        await setupNext10NJ3();
    }


    /**
     * Cancels all notifications using the OneSignal API.
     * @async
     * @function
     * @returns {Promise<void>}
     */
    const cancelAllNotifications = async () => {
        await Promise.all(state.notifications.map(async (notification) => {
            const options = {
                method: 'DELETE',
                headers: {accept: 'application/json', Authorization: `Basic ${API_KEY}`}
            };

            const notificationId = notification.id;

            try {
                const response = await fetch(`https://onesignal.com/api/v1/notifications/${notificationId}?app_id=${APP_ID}`, options);
                const data = await response.json();
                console.log(data);
            } catch (err) {
                console.error(err);
            }
        }));
        getNotifications();
    }


    useEffect(() => {
        if(state.notifications.length === 0) return;
        if(state.notificationsDateUpdated) return;
        let newNotifications = state.notifications.map(notification => {
            let d = new Date(notification.send_after * 1000);
            let dformat = [d.getDate(),
                d.getMonth()+1,
                d.getFullYear()].join('/')+' '+
               [d.getHours(),
                d.getMinutes()].join(':');
            return {...notification, send_after_redable_format: dformat, send_after_date_format: d};
        })

        newNotifications = newNotifications.filter(notification => {
            return notification.send_after_date_format >= new Date();
        });



        // Sort by name and date
        const sortedNotifications = newNotifications.sort((a, b) => {
            if(a.name < b.name) return -1;
            if(a.name > b.name) return 1;
            if(a.send_after_date_format < b.send_after_date_format) return -1;
            if(a.send_after_date_format > b.send_after_date_format) return 1;
            return 0;
        });

        setState({...state, notifications: sortedNotifications, notificationsDateUpdated: true})
        console.log(state.notifications);
    }, [state.notifications])

    useEffect(() => {
        getNotifications();
    }, [])

    return (
        <div>
            <div>
                {notifications.map(notification => (

                    <button
                        key={notification.id}
                        onClick={() => sendNotification(notification)}
                    >
                        Send {notification.id}
                    </button>
                ))}
                <button
                    onClick={() => setupNextRappel0()}
                >
                    Setup next Rappel0
                </button>

                <button
                    onClick={() => setupNextOSS()}
                >
                    Setup next OSS notifications
                </button>
            </div>
            <div>

                <button
                    style={{backgroundColor: 'green', color: 'white'}}
                    onClick={() => setupNext10daysNJNotifications()}
                >
                    Setup next 10 days NJ notifications
                </button>
            </div>
            <div>
                <button
                    onClick={() => getNotifications()}
                >
                    Get notifications
                </button>
                <button
                    style={{backgroundColor: 'red', color: 'white'}}
                    onClick={() => cancelAllNotifications()}
                >
                    Cancel all notifications
                </button>
            </div>
            {state.error && <div style={{backgroundColor: 'red', color: 'white'}}>{state.error}</div>}
            {state.warning && <div style={{backgroundColor: 'yellow', color: 'black'}}>{state.warning}</div>}
            { parcours.map(course => (
                <div
                    key={course.key}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        padding: '10px',
                        border: '1px solid black',
                        borderRadius: '10px'
                    }}
                >
                    <h2>{course.name}</h2>
                    <h3>Envoyer les notifications:</h3>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '10px',
                            gap: '10px'
                        }}
                    >
                        <motion.button
                            className="box"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => {sendNotification(notifications.find(notification => notification.id === `RaidEDHEC0${course.key}`))}}
                        >
                            Ligne de départ - {course.name}
                        </motion.button>
                        <motion.button
                            className="box"
                            style={{backgroundColor: '#2EA883'}}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => {sendNotification(notifications.find(notification => notification.id === `RaidEDHEC1${course.key}`))}}
                        >
                            Rappel activation de l'app
                        </motion.button>

                    </div>

                </div>
            ))}

            {state.notifications.length > 0 && (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Contents</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.notifications.map(notification => (
                                <tr key={notification.id}>
                                    <td>{notification.name}</td>
                                    <td>{notification.send_after_redable_format}</td>
                                    <td>{notification.contents.en}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default Notifications;
