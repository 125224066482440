import { useState, useEffect } from "react";
import Customers from "../../../controllers/Customers";
import styles from './CustomerList.module.css';
import Header from "../../Header/Header";
import { Link } from "react-router-dom";


const CustomerList = () => {

    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        (async() => {
            // Load customer list

            const CustomersInstance = await new Customers()
            let customerList = await CustomersInstance.getAll()

            setCustomers(customerList)

        })()
    }, []);

    useEffect(() => {
        if(customers.length === 0) return;
        (async() => {
            const CustomersInstance = await new Customers()
            let newCustomersInfoPromises = customers.map(async (customer) => {
                const numberOfUsers = await CustomersInstance.getNumberOfCustomers(customer.codes[0])
                return {
                    ...customer,
                    numberOfUsers
                }
            })
            const newCustomersInfo = await Promise.all(newCustomersInfoPromises)
            setCustomers(newCustomersInfo)
        })()
    }, [customers.length]);

    return (
        <>
            <Header />
            <div className={styles.customerList}>
                <h1>CustomerList</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Nom du partenaire</th>
                            <th>Code du partenaire</th>
                            <th>Nombre d'utilisateurs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.map((customer) => (
                            <tr key={customer.id}>
                                <td>{customer.name}</td>
                                <td>{customer.codes[0]}</td>
                                <td>{customer.numberOfUsers}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Link to="/add-customer" className={styles.addCustomerLink}>Ajouter une entreprise cliente</Link>
            </div>
        </>
    );
}

export default CustomerList;
