import { collection, addDoc, getDoc, getDocs, doc, updateDoc, where, query } from "firebase/firestore";
import db from './config/firebase'
import Partners from "./Partners";


class Offers {

    getPartnerOffers = async (partnerId) => {
        const PartersInstance = await new Partners()
        const partner = await PartersInstance.getPartner(partnerId)
        return partner.offers
    }

    addNewOffer = async (partnerId, newOfferInfo) => {
        const PartersInstance = await new Partners()
        const partner = await PartersInstance.getPartner(partnerId)
        const offers = partner.offers || []
        offers.push(newOfferInfo)
        await PartersInstance.updatePartner(partnerId, {offers})
    }

    publishOffer = async (partnerId, offerName) => {
        return new Promise(async(resolve, reject) => {
            try{
                const PartersInstance = await new Partners()
                const partner = await PartersInstance.getPartner(partnerId)
                const offers = partner.offers.map((offer) => {
                    if(offer.name === offerName){
                        offer.published = true
                    }
                    return offer
                })
                partner.offers = offers
                await PartersInstance.updatePartner(partnerId, partner)
                resolve()
            }catch(e){
                reject(e)
            }
        })
    }

    unpublishOffer = async (partnerId, offerName) => {
        return new Promise(async(resolve, reject) => {
            try{
                const PartersInstance = await new Partners()
                const partner = await PartersInstance.getPartner(partnerId)
                const offers = partner.offers.map((offer) => {
                    if(offer.name === offerName){
                        offer.published = false
                    }
                    return offer
                })
                partner.offers = offers
                await PartersInstance.updatePartner(partnerId, partner)
                resolve()
            }catch(e){
                reject(e)
            }
        })
    }
}

export default Offers
