import React, { useState } from 'react';
import {
		email as RequestedIdentifiant,
		password as RequestedPassword,
		token
 } from '../../config/auth';
import { useCookies } from 'react-cookie';
import styles from './Authentification.module.css';


function Authentification() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [cookies, setCookie] = useCookies();

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log(`Email: ${email} Password: ${password}`);
		if(email === RequestedIdentifiant && password === RequestedPassword){
			setCookie('tokenLogin', token, { path: '/' });
			window.location.href = '/';
		}else{
			alert('Identifiant ou mot de passe incorrect')
		}
	};

	return (
		<form onSubmit={handleSubmit} className={styles.form}>
			<h1 className={styles.h1}>Connexion</h1>
			<label className={styles.label}>
				Email:
				<input type="email" className={styles.inputEmail} value={email} onChange={handleEmailChange} />
			</label>
			<label className={styles.label}>
				Mot de passe:
				<input type="password" className={styles.inputPassword} value={password} onChange={handlePasswordChange} />
			</label>
			<button type="submit" className={styles.buttonSubmit}>Se connecter</button>
		</form>
	);
}

export default Authentification;
