import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./OfferList.module.css";
import Partners from "../../controllers/Partners";
import Offers from "../../controllers/Offers";
import PromoCodes from "../../controllers/PromoCodes";
import Modal from "../Modal/Modal";
import Header from "../Header/Header";

const OfferList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState({ id });
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(null);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "partner") {
      if (value === "") return setPartner({ id: "" });
      setPartner({ id: value });
    }
  };

  useEffect(() => {
    (async () => {
      if (!partner.id) {
        setOffers([]);
        return navigate(`/offers`, { replace: true });
      }

      // Update URL
      navigate(`/offers/partner/${partner.id}`, { replace: true });

      // Load partner offers
      const offersInstance = new Offers();
      const offersList = await offersInstance.getPartnerOffers(partner.id);
      setOffers(offersList);
    })();
  }, [partner]);

  useEffect(() => {
    (async () => {
      // Load partners list

      const PartersInstance = await new Partners();
      const partnersList = await PartersInstance.getAll();

      console.log("partnersList", partnersList);

      setPartners(partnersList);
    })();
  }, []);

  useEffect(() => {
    if (offers?.length > 0 && offers[0].numberOfAvailableCodes !== undefined)
      return;
    refreshPromoCodesAvailability();
  }, [offers, partners]);

  const refreshPromoCodesAvailability = async () => {
    if (offers?.length > 0 && partners?.length > 0) {
      const promoCodesInstance = new PromoCodes();
      const newOffersPromises = offers.map(async (offer) => {
        const currentPartner = partners.find((p) => p.id === partner.id);

        if (currentPartner === undefined) return;

        const numberOfAvailableCodes =
          await promoCodesInstance.getNumberOfAvailablePromoCodes(
            currentPartner.partnerName,
            offer.name
          );

        return {
          ...offer,
          numberOfAvailableCodes,
        };
      });
      Promise.all(newOffersPromises).then((newOffers) => {
        setOffers(newOffers);
      });
    }
  };

  const addPromoCodes = async (offer, number) => {
    if (number === undefined) number = 50;
    if (isNaN(number))
      return setError("Le nombre de coupons doit être un nombre");
    if (number <= 0 || number > 100)
      return setError("Le nombre de coupons doit être compris entre 1 et 100");

    try {
      const currentPartner = partners.find((p) => p.id === partner.id);

      if (currentPartner === undefined) return;

      const promoCodesInstance = new PromoCodes();
      await promoCodesInstance.generatePromoCodes(
        currentPartner.partnerName,
        offer,
        number
      );

      setModal(
        <Modal
          type="success"
          message="Les codes promo ont été générés avec succès"
          onClose={() => setModal(null)}
        />
      );
      refreshPromoCodesAvailability();
    } catch (e) {
      setError(e.message);
    }
  };

  const publishedOffer = async (offer) => {
    try {
      const offersInstance = new Offers();
      await offersInstance.publishOffer(partner.id, offer.name);
      setModal(
        <Modal
          type="success"
          message="L'offre a été publiée avec succès"
          onClose={() => setModal(null)}
        />
      );
      window.location.reload(false); // Reload page to update offers list
    } catch (e) {
      setError(e);
    }
  };

  const unpublishedOffer = async (offer) => {
    try {
      const offersInstance = new Offers();
      await offersInstance.unpublishOffer(partner.id, offer.name);
      setModal(
        <Modal
          type="success"
          message="L'offre a été dépubliée avec succès"
          onClose={() => setModal(null)}
        />
      );
      window.location.reload(false); // Reload page to update offers list
    } catch (e) {
      setError(e);
    }
  };

  if (error) return <>{error}</>;

  return (
    <>
      <Header />
      <div className={styles.partnerList}>
        {modal && modal}
        <h2>Liste des offres</h2>
        <form style={offers === undefined ? { height: "auto" } : {}}>
          <select
            name="partner"
            value={partner.id}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un partenaire</option>
            {partners.map((p, index) => (
              <option value={p.id} key={index}>
                {p.partnerName}
              </option>
            ))}
          </select>
        </form>
        {offers?.length > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Prix</th>
                  <th>Nom du code promo</th>
                  <th>Pourcentage de réduction</th>
                  <th>Coupons disponibles</th>
                  <th>Nombre de coupon max</th>
                  <th>Ventes multiples pour un même utilisateur</th>
                  <th>Créer de nouveaux coupons</th>
                  <th>Offre publiée</th>
                  <th>Liste des codes</th>
                </tr>
              </thead>
              <tbody>
                {offers.map((offer, index) => {
                  const currentPartner = partners.find(
                    (p) => p.id === partner.id
                  );
                  return (
                    <tr key={index}>
                      <td>{offer.type}</td>
                      <td>{offer.description}</td>
                      <td>{offer.price}</td>
                      <td>{offer.promoCode}</td>
                      {offer.type === "promo" ? (
                        <td>-{offer.percentage} %</td>
                      ) : (
                        <td></td>
                      )}

                      <td>{offer.numberOfAvailableCodes}</td>
                      <th>{offer.number}</th>
                      <td>{offer.multipleSell ? "Oui" : "Non"}</td>
                      <td>
                        <button onClick={() => addPromoCodes(offer, 10)}>
                          Ajouter 10 coupons
                        </button>
                        <button onClick={() => addPromoCodes(offer, 25)}>
                          Ajouter 25 coupons
                        </button>
                        <button onClick={() => addPromoCodes(offer, 50)}>
                          Ajouter 50 coupons
                        </button>
                      </td>
                      <td>
                        {offer.published ? (
                          <>
                            <p>Oui</p>
                            <button onClick={() => unpublishedOffer(offer)}>
                              Dépublier l'offre
                            </button>
                          </>
                        ) : (
                          <>
                            <p>Non</p>
                            <button onClick={() => publishedOffer(offer)}>
                              Publier l'offre
                            </button>
                          </>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/offers/export/${currentPartner.partnerName}/${offer.name}`}
                        >
                          Exporter les codes
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {partner !== undefined && partner.id !== "" && (
          <Link
            to={`/offers/add/partner/${partner.id}`}
            className={styles.addPartnerLink}
          >
            Ajouter une offre
          </Link>
        )}
      </div>
    </>
  );
};

export default OfferList;
