import { useState } from "react";
import FileUploads from "../../controllers/FileUploads";
import Partners from "../../controllers/Partners";
import Modal from "../Modal/Modal";

const ImageUploader = (props) => {

	const { partner, setFileRef, setLogo, handleImageChange } = props;
	const { partnerName, id } = partner || {};

	console.log({partnerName, id})

	let disabled = false;

	if(partnerName === undefined || partnerName === ''){
		disabled = true;
	}

	const [image, setImage] = useState(null);
	const [modal, setModal] = useState(null);
	const [success, setSuccess] = useState(null);

	const handleFileChange = (e) => {
		if (e.target.files[0]) {
			setImage(e.target.files[0]);
		}
		handleImageChange(e);
	};

	const handleUpload = async(e) => {
		e.preventDefault();
		

		if (image) {
			const extension = image.name.split('.').pop();
			const fileRef = `partners/${partnerName}/${partnerName}-logo.${extension}`;
			setFileRef(fileRef);

			try{
				const FileUploadInstance = new FileUploads();
				await FileUploadInstance.uploadOfferImage(image, fileRef);
				setModal(
					<Modal 
						type="success"
						message="L'image a bien été uploadée"
						onClose={() => setModal(null)}
					/>
				);
				setSuccess(<div style={{
					color: 'rgb(12 152 12)',
				}}>Image {image.name} correctement uploadée</div>);
				const partnersInstance = await new Partners();

				if(id === undefined) return;

				// const logo = await partnersInstance.getPartnerLogo(id);
				// console.log({logo})
				// setLogo(logo);

			}catch(e){
				console.error(e)
				setModal(
					<Modal 
						type="error"
						message="Une erreur est survenue lors de l'upload de l'image"
						onClose={() => setModal(null)}
					/>
				);
			}
		}
	};

	return (
		<div>
			{modal && modal}
			{success && success}
			<input type="file" onChange={handleFileChange} disabled={disabled} />
			<button onClick={handleUpload}>Uploader l'image</button>
		</div>
	);
};

export default ImageUploader;
