// src/components/EditPartner/EditPartner.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './EditPartner.module.css';
import Partners from '../../../controllers/Partners'
import ImageUploader from '../../ImageUploader/ImageUploader';
import Header from '../../Header/Header';

const EditPartner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [partner, setPartner] = useState(null);
  const [error, setError] = useState(null)
  const [image, setImage] = useState(null)

  useEffect(() => {
  
    // Load partner data
    (async ()=>{
      try{
        const PartersInstance = await new Partners()
        const partnerInfo = await PartersInstance.getPartner(id)
        setPartner(partnerInfo)
        setImage(partnerInfo.logoURL)
      }catch(e){
        console.error(e)
        setError(e)
      }
      
    })()

  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartner({
      ...partner,
      [name]: value,
    });
  };

  const setFileRef = (fileRef) => {
    console.log({fileRef})
    setPartner({
      ...partner,
      logo: fileRef
    })
  }

  // const setLogo = (logo) => {
  //   setPartner({
  //     ...partner,
  //     logo: logo
  //   })
  //   console.log(logo)
  // }

  function handleImageChange(event) {
    console.log({event})
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
    };
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Save the updated partner data here
    try{
      const PartersInstance = await new Partners()
      await PartersInstance.updatePartner(id, partner)
      navigate('/');
    }catch(e){
      console.error(e)
      setError(e)
    }

  };

  if(error){
    return <div>{error}</div>;
  }

  if (!partner) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <div className={styles.editPartner}>
        <h2>Modifier un partenaire</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="partnerName">Nom du partenaire :</label>
          <input
            type="text"
            id="partnerName"
            name="partnerName"
            value={partner.partnerName}
            onChange={handleChange}
          />

          <label htmlFor="category">Catégorie :</label>
          <select id="category" name="category" value={partner.category} onChange={handleChange}>
            <option value="Food">Food</option>
            <option value="Sport">Sport</option>
            <option value="Wellness">Wellness</option>
          </select>

          <label htmlFor="website">Site Web :</label>
          <div
            style={{
              width: '100%',
            }}
          >
            <input
              type="text"
              id="website"
              name="website"
              value={partner.website}
              onChange={handleChange}
              style={{float: 'left', maxWidth: '50%'}}
            />
            <span><a href={partner.website} target="_blank" rel="noreferrer">Consulter</a></span>
          </div>
          

          <label htmlFor="logo">Logo :</label>
          <img
            style={{ width: '500px', border: '1px solid black' }}
            src={image}
            alt={partner.partnerName}
          />
          <ImageUploader
            partner={partner}
            setFileRef={setFileRef}
            // setLogo={setLogo}
            handleImageChange={handleImageChange}
          />


          <label htmlFor="description">Description :</label>
          <textarea
            id="description"
            name="description"
            value={partner.description}
            onChange={handleChange}
          ></textarea>

          <button type="submit">Enregistrer les modifications</button>
        </form>
      </div>
    </>
  );
};

export default EditPartner;
