import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import db from './config/firebase';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {email, password} from "../config/auth";

class Metrics {

    constructor(){
        return new Promise((resolve, reject) => {
            try{
                this.loginAsAdmin()
                resolve(this)
            }
            catch(e){
                reject(e)
            }
        })
    }

    loginAsAdmin = async () => {
        const auth = getAuth();
        return new Promise(async(resolve, reject) => {
            try{
                await signInWithEmailAndPassword(auth, email, password)
                resolve()
            }catch(e){
                reject(e)
            }
        })
    }

    /**
     * Retrieves the total number of users.
     * @returns {Promise<number>} The total number of users.
     */
    getTotalUsers = async () => {
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        return usersSnapshot.size;
    }

    /**
     * Retrieves the number of active users within the last 7 days.
     * @returns {Promise<number>} The number of active users.
     */
    getNumberOfActiveUsers = async () => {
        const endDate = new Date().setHours(23, 59, 59, 999);
        const startDate = new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0);
        const activities = query(collection(db, 'activities'), where('createdDate', '>=', new Date(startDate)), where('createdDate', '<=', new Date(endDate)));
        const activitiesSnapshot = await getDocs(activities);
        const usersWallets = activitiesSnapshot.docs.map(doc => doc.data().user);
        const uniqueUsersWallets = [...new Set(usersWallets)];
        return uniqueUsersWallets.length;
    }

    /**
     * Retrieves the number of B2B and Partnerships users.
     *
     * @returns {Promise<number>} The number of B2B and Partnerships users.
     */
    getNumberOfB2BAndPartnershipsUsers = async () => {
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const b2bClients = query(collection(db, 'customers'), where('test', 'in', [false]));
        const b2bClientsSnapshot = await getDocs(b2bClients);

        const codes = collection(db, 'paywallCodes');

        const b2bCodes = b2bClientsSnapshot.docs.map(async (doc) => {
            const codesSnapshot = await getDocs(query(codes, where('customerId', '==', doc.id)));
            return codesSnapshot.docs.map(doc => doc.data().code);
        });

        const b2bCodesArray = await Promise.all(b2bCodes);
        const b2bCodesFlatArray = b2bCodesArray.flat();

        const b2bUsers = usersSnapshot.docs.filter(doc => b2bCodesFlatArray.includes(doc.data().paywallCode));
        return b2bUsers.length;
    }

}

export default Metrics;
