import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyBIcCUzHwIv1zsCxplDIps6YAaRbyr3txA",
	authDomain: "omnishape-79fb2.firebaseapp.com",
	databaseURL: 'https://omnishape-79fb2.firebaseio.com',
	projectId: "omnishape-79fb2",
	storageBucket: "omnishape-79fb2.appspot.com",
	messagingSenderId: "389193431901",
	appId: "1:389193431901:web:57a1555569691436ea06b4",
	measurementId: "G-T50ZWHXER2",
  };

const app = initializeApp(firebaseConfig)

const db = getFirestore(app);
const storage = getStorage(app);

export default db

export { db, storage }
