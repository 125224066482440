import { useEffect, useState } from "react";
import Metrics from "../../controllers/Metrics";
import styles from './MetricsList.module.css';
import Header from "../Header/Header";

const MetricsList = () => {

    const [state, setState] = useState({
        metrics: []
    });

    useEffect(() => {

        (async () => {
            // Load metrics

            const metricsInstance = await new Metrics()
            let totalUsers = await metricsInstance.getTotalUsers()

            let activeUsers = await metricsInstance.getNumberOfActiveUsers()

            let b2bAndPartnershipsUsers = await metricsInstance.getNumberOfB2BAndPartnershipsUsers()
            let b2cUsers = totalUsers - b2bAndPartnershipsUsers

            setState({
                ...state,
                metrics: [
                    ...state.metrics,
                    {
                        name: 'Total users (including testing users)',
                        value: totalUsers
                    },
                    {
                        name: 'B2C users',
                        value: b2cUsers
                    },
                    {
                        name: 'Total B2B & partnerships users',
                        value: b2bAndPartnershipsUsers
                    },
                    {
                        name: 'Active users (last 7 days)',
                        value: activeUsers
                    },
                ]
            })
        }
        )()
    }, []);


    return (
        <>
            <Header />
            <div className={styles.metricsList}>
                <h1>Metrics</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.metrics.map((metric) => (
                            <tr key={metric.name}>
                                <td>{metric.name}</td>
                                <td>{metric.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default MetricsList;
