import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./AddOffer.module.css";
import Partners from "../../controllers/Partners";
import Offers from "../../controllers/Offers";
import Header from "../Header/Header";

const AddOffer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [offer, setOffer] = useState({
    name: "",
    description: "",
    type: "",
    percentage: "",
    price: "",
    number: "",
    numberInfinity: true,
    multipleSell: false,
    published: false,
    // start_date
    // expiration_date
  });
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState({ id });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "partner") {
      return setPartner({ id: value });
    }
    setOffer({
      ...offer,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const offersInstance = new Offers();
      await offersInstance.addNewOffer(partner.id, offer);
      navigate(`/offers/partner/${partner.id}`);
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    // Load partner data
    (async () => {
      try {
        const partnersInstance = await new Partners();
        const partners = await partnersInstance.getAll();
        setPartners(partners);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!partner.id) return navigate(`/offers`, { replace: true });

    navigate(`/offers/add/partner/${partner.id}`, { replace: true });
  }, [partner.id]);

  useEffect(() => {
    if (offer.type === "promo") {
      document.querySelector('input[name="percentage"]').disabled = false;
    } else {
      document.querySelector('input[name="percentage"]').disabled = true;
      setOffer({ ...offer, percentage: "" });
    }
  }, [offer.type]);

  useEffect(() => {
    if (offer.numberInfinity) {
      document.querySelector('input[name="number"]').disabled = true;
      setOffer({ ...offer, number: Infinity });
    } else {
      document.querySelector('input[name="number"]').disabled = false;
      setOffer({ ...offer, number: "" });
    }
  }, [offer.numberInfinity]);

  return (
    <>
      <Header />
      <div className={styles.addOffer}>
        <h2>Ajouter une offre</h2>
        <form onSubmit={handleSubmit}>
          <select
            name="partner"
            value={partner.id}
            onChange={handleChange}
            required
          >
            <option value="">Nom du Partenaire</option>
            {partners.map((partner, index) => (
              <option value={partner.id} key={index}>
                {partner.partnerName}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="name"
            placeholder="Nom de l'offre"
            value={offer.name}
            onChange={handleChange}
            required
          />
          <textarea
            name="description"
            placeholder="Description"
            value={offer.description}
            onChange={handleChange}
            required
          />
          <select
            name="type"
            value={offer.type}
            onChange={handleChange}
            required
          >
            <option value="">Type d'offre</option>
            <option value="promo">Promo</option>
            <option value="deal">Deal</option>
          </select>
          <input
            type="number"
            name="percentage"
            placeholder="Pourcentage (si promo)"
            value={offer.percentage}
            onChange={handleChange}
            min="0"
            max="100"
            step="1"
            disabled={offer.type !== "promo"}
          />
          <input
            type="number"
            name="price"
            placeholder="Prix (en token)"
            value={offer.price}
            onChange={handleChange}
            required
          />
          {/* NEW FIELD */}
          <input
            type="text"
            name="promoCode"
            placeholder="Nom du promo code"
            value={offer.promoCode}
            onChange={handleChange}
            required
          />
          <div>
            <label>
              <input
                type="checkbox"
                name="numberInfinity"
                checked={offer.numberInfinity}
                onChange={(e) =>
                  setOffer({ ...offer, numberInfinity: e.target.checked })
                }
              />
              Nombre d'offres illimité
            </label>
          </div>
          <input
            type="number"
            name="number"
            placeholder="Nombre d'offres disponibles"
            value={offer.number}
            onChange={handleChange}
            required
          />
          <div>
            <label>
              <input
                type="checkbox"
                name="multipleSell"
                checked={offer.multipleSell}
                onChange={(e) =>
                  setOffer({ ...offer, multipleSell: e.target.checked })
                }
              />
              Peut être acheté plusieurs fois par le même utilisateur
            </label>
          </div>
          <div>
            <label for="start_date">
              Date de début de l'offre (non obligatoire):
            </label>
            <br />
            <input
              type="date"
              name="start_date"
              id="start_date"
              onChange={(e) =>
                setOffer({ ...offer, start_date: new Date(e.target.value) })
              }
            />
          </div>
          <div>
            <label for="expiration_date">
              Date de début de l'offre (non obligatoire):
            </label>
            <br />
            <input
              type="date"
              name="expiration_date"
              id="expiration_date"
              onChange={(e) =>
                setOffer({
                  ...offer,
                  expiration_date: new Date(e.target.value),
                })
              }
            />
          </div>
          <button type="submit">Ajouter l'offre</button>
        </form>
      </div>
    </>
  );
};

export default AddOffer;
