// src/components/Partners/AddPartner/AddPartner.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AddPartner.module.css';
import Partners from '../../../controllers/Partners'
import ImageUploader from '../../ImageUploader/ImageUploader';
import Header from '../../Header/Header';

const AddPartner = () => {
    const navigate = useNavigate();
    const [partner, setPartner] = useState({
        partnerName: '',
        category: '',
        website: '',
        logo: '',
        description: '',
        offers: [],
    });
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPartner({
            ...partner,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        (async () => {
        
            try{
                const PartersInstance = await new Partners()
                const id = await PartersInstance.addNewPartner(partner)

                navigate(`/edit-partner/${id}`);
            }catch(e){
                console.error(e)
                setError(e)
            }
        })()

    };

    function handleImageChange(event) {
        console.log({event})
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImage(reader.result);
        };
    }

    const setFileRef = (fileRef) => {
        setPartner({
            ...partner,
            logo: fileRef
        })
    }

    if(error){
        return <div>{error}</div>;
    }

    return (
        <>
            <Header />
            <div className={styles.addPartner}>
                <h2>Ajouter un partenaire</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="partnerName"
                        placeholder="Nom du partenaire"
                        value={partner.partnerName}
                        onChange={handleChange}
                        required
                    />
                    <select
                        name="category"
                        value={partner.category}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Sélectionnez une catégorie</option>
                        <option value="Food">Food</option>
                        <option value="Sport">Sport</option>
                        <option value="Wellness">Wellness</option>
                    </select>
                    <input
                        type="text"
                        name="website"
                        placeholder="Site Web"
                        value={partner.website}
                        onChange={handleChange}
                    />
                    <ImageUploader
                        partner={partner}
                        setFileRef={setFileRef}
                        handleImageChange={handleImageChange}
                    />
                    <textarea
                        name="description"
                        placeholder="Description"
                        value={partner.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <button type="submit">Ajouter le partenaire</button>
                </form>
            </div>
        </>
    );
};

export default AddPartner;
