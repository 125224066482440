import { useState, useEffect } from "react";
import styles from './Activities.module.css';
import Header from "../../Header/Header";
import { Link } from "react-router-dom";
// import Activities from "../../../controllers/Activities";
import ActivitiesRaidEDHEC from "../../../controllers/ActivitiesRaidEDHEC";


const ActivitiesRaidEDHECList = () => {
    const [state, setState] = useState({
        activities: [],
        loading: true,
        numberOfNewUser: 0
    });

    const [activities, setactivities] = useState([]);

    useEffect(() => {
        (async() => {

            const ActivitiesInstance = new ActivitiesRaidEDHEC();
            let activityList = await ActivitiesInstance.getLoutreFinishers();

            setactivities(activityList)

            const numberOfNewUser = await ActivitiesInstance.getNumberOfNewUsers();
            console.log({ numberOfNewUser })
            setState({
                ...state,
                numberOfNewUser,
                loading: false
            });

        })()
    }, []);

    return (
        <>
            <Header />
            <p>New registered users: {state.numberOfNewUser} from Raid EDHEC</p>
            {/* <div className={styles.activityList}>
                <h1>activityList</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Nom du partenaire</th>
                            <th>Code du partenaire</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activities.map((customer) => (
                            <>
                                <tr key={customer.id}>
                                    <td>{customer.name}</td>
                                    <td>{customer.codes[0]}</td>
                                </tr>
                                
                            </>
                        ))}
                    </tbody>
                </table>
                <Link to="/add-customer" className={styles.addCustomerLink}>Ajouter une entreprise cliente</Link>
            </div> */}
        </>
    );
}

export default ActivitiesRaidEDHECList;
