import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import db from './config/firebase';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {email, password} from "../config/auth";

class Customers {

    constructor(){
        return new Promise((resolve, reject) => {
            try{
                this.loginAsAdmin()
                resolve(this)
            }
            catch(e){
                reject(e)
            }
        })
    }

    loginAsAdmin = async () => {
        const auth = getAuth();
        return new Promise(async(resolve, reject) => {
            try{
                await signInWithEmailAndPassword(auth, email, password)
                resolve()
            }catch(e){
                reject(e)
            }
        })
    }

    /**
     * Retrieves all customers with their corresponding paywall codes.
     * @returns {Promise<Array>} An array of customers with their paywall codes.
     */
    getAll = async () => {
        const customersCollectionRef = collection(db, 'customers');

        const customerDocs = await getDocs(customersCollectionRef);
        const customers = customerDocs.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        const customersWithPaywallCodes = await Promise.all(
            customers.map(async (customer) => {
                const customerCodesQuery = query(collection(db, 'paywallCodes'), where('customerId', '==', customer.id));

                const codesSnapshot = await getDocs(customerCodesQuery);

                const codes = codesSnapshot.docs.map(doc => doc.data().code);

                return {
                    ...customer,
                    codes
                };
            })
        );

        return customersWithPaywallCodes;
    }


    addNewCustomer = async (customer) => {
        const code = customer.code
        delete customer.code

        const customerRef = await addDoc(collection(db, 'customers'), {
            ...customer
        });

        await addDoc(collection(db, 'paywallCodes'), {
            customerId: customerRef.id,
            code,
            active: true
        });

        return customerRef.id;
    }

    getNumberOfCustomers = async (code) => {
        const usersRef = query(collection(db, 'users'), where('paywallCode', '==', code));
        const usersSnapshot = await getDocs(usersRef);
        return usersSnapshot.size;
    }
}

export default Customers;
