import { collection, addDoc, getDoc, setDoc, getDocs, doc, updateDoc, where, query } from "firebase/firestore";
import db from './config/firebase'
import Partners from "./Partners";
import voucher_codes from 'voucher-code-generator';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {email, password} from "../config/auth";


class PromoCodes {

    constructor(){
        return new Promise((resolve, reject) => {
            try{
                this.loginAsAdmin()
                resolve(this)
            }
            catch(e){
                reject(e)
            }
        })
    }

    loginAsAdmin = async () => {
        const auth = getAuth();
        return new Promise(async(resolve, reject) => {
            try{
                await signInWithEmailAndPassword(auth, email, password)
                resolve()
            }catch(e){
                reject(e)
            }
        })
    }

    getNumberOfAvailablePromoCodes = async (partnerName, OfferName) => {
        console.log('partnerName', partnerName, 'OfferName', OfferName)

        return new Promise(async(resolve, reject) => {
            const querySnapshot = await getDocs(collection(db, 'promoCode', partnerName, 'codes'));
            if(querySnapshot.empty){
                reject("No such document!")
            }

            let promoCodes = []
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if(data.available && data.type === OfferName) promoCodes.push(data)
            })

            resolve(promoCodes.length)
        })
    }

    generatePromoCodes = async (partnerName, offer, number) => {

        const voucherCodes = voucher_codes.generate({
            length: 8,
            count: number
        });

        const newCodes = voucherCodes.map((code) => {
            return {
                available: true,
                code: code,
                type: offer.name,
            }
        })

        return new Promise(async(resolve, reject) => {
            if(offer.number !== Infinity) reject('Generate codes only for unlimited offers. Please contact support.')

            try {
                const partnerRef = doc(db, 'promoCode', partnerName)
                const docSnap = await getDoc(partnerRef);

                if(!docSnap.exists()) {
                    await setDoc(partnerRef, {});
                }

                for (const code of newCodes) {
                    await addDoc(collection(db, 'promoCode', partnerName, 'codes'), code);
                }

                resolve(true)
            } catch(err) {
                reject(err)
            }
        })
    }


    getPromoCodes = async (partnerName, offerName) => {
        const querySnapshot = await getDocs(collection(db, 'promoCode', partnerName, 'codes'));

        if(querySnapshot.empty){
            throw new Error("No such document!")
        }

        let promoCodes = []
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if(data.type === offerName) promoCodes.push(data)
        })

        return promoCodes
    }

}

export default PromoCodes
